import React, { useState, useEffect } from 'react';
import { Chip } from '@mui/material';
import { connect } from 'react-redux';
import {
  updateEventStatus,
  updateDummyEventStatus,
  resetEventById,
  resetDummyEventById,
  removeFromCart,
  updateCartItemStatus,
} from '../../../actions';

const CartElement = (props) => {
  console.log("cart card props", props);
  // const [price, setPrice] = useState(null);
  const variantName = props.data.variantName;
  const quantity = props.data.quantity;
  const price = props.data.price;
  const imageUrl = props.data.variantImage || props.data.imageUrl || props.data.variantImageUrl ;

  return (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
      <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>

      <img height="50px" width="50px" src={imageUrl} alt="" />
      <div style={{ fontSize: '13px' }}>
        {variantName}
      </div>
      </div>
      <div style={{display: "flex"}}>
      {price && (
        <Chip size='small' label={`Price: ${price * quantity}`} />
        )}
      <Chip size='small' label={`Qty: ${quantity}`} />
        </div>
    </div>
  );
};

const mapStateToProps = ({ cart, event, dummyEvent }) => ({
  cart,
  event,
  dummyEvent,
});

export default connect(mapStateToProps, {
  updateEventStatus,
  updateDummyEventStatus,
  resetEventById,
  resetDummyEventById,
  removeFromCart,
  updateCartItemStatus,
})(CartElement);
