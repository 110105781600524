import React, { useState, useEffect } from 'react';
import { Button, Modal, TextField, IconButton } from '@mui/material';

import { useNavigate } from 'react-router-dom';

import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';
import ModalWrapper from './modalWrapper';

import {
  addDummyEvent,
  updateEventStatus,
  createDummyEvents,
  updateCartItemStatus,
  updateTicketStatus,
  confirmEvents,
  cartconfirmed,
  cartCleared,
  createUserOrder,
  // confirmUserOrder,
  dummyEventsSaved,
  eventsSaved,
  createCart,
  updateDummyEventStatus,
  setModalState,
} from '../../actions/index';

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    width: 'auto',
    height: 'auto', 
    padding: '10px', 
    maxWidth: '100%', 
    maxHeight: '90vh', 
    overflow: 'auto', 
  },
  header: {
    // textAlign: 'center',
    // alignSelf: 'center'
  },
  form: {
    width: '100%',
    height: '50%',
    display: 'flex',
    justifyContent: 'center',
    // alignItems: 'center',
  },
  cartItem: {
    display: 'flex',
    gap: '20px',
    justifyContent: 'space-between',
  },
}));


const AddConfirmTicketModal = (props) => {
  console.log("confirm tkt props", props);
  const navigate = useNavigate();

  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(props.modal.isLoading);
  }, [props.modal.isLoading]);

  const handleClose = () => {
    props.closeModal();
  };

  const getEventIdsByStatus = (status, events) => {
    console.log('   inside getCheckedEventIds', events);
    const checkedEvents = events.filter((event) => event.status === status);
    const checkedEventIds = checkedEvents.map((event) => event.id);
    return checkedEventIds;
  };

  const handleConfirmButtonClick = async () => {
    props.setModalState({
      visible: true,
      modalName: 'loading',
      isLoading: true,
    });
  
    try {
      if (props.event.events.events){
        const events = getEventIdsByStatus(`ADDED_TO_CART_OF_${props.ticket.ticket.id}`, props.event.events.events);
        const sortedEvents = props.event.events.events
          .filter((event) => {
            if (Array.isArray(events)) {
              return events.includes(event.id) && event.status === `ADDED_TO_CART_OF_${props.ticket.ticket.id}`;
            }
            return false;
          })
          .map((event) => ({ ...event, status: 'CONFIRMED' }));
        console.log('sorted  events', sortedEvents);
        props.eventsSaved(props.ticket.ticket.id);
        if (sortedEvents.length > 0) {
          props.updateEventStatus({ status: 'CONFIRMED', event_ids: events });
        }
      }
  
      if (props.dummyEvent.dummyEvents.dummyEvents){
        const dummyEvents = getEventIdsByStatus(`ADDED_TO_CART_OF_${props.ticket.ticket.id}`, props.dummyEvent.dummyEvents.dummyEvents);
        console.log('user dummy events', dummyEvents);
        const sortedDummyEvents = props.dummyEvent.dummyEvents.dummyEvents
          .filter((event) => {
            if (Array.isArray(dummyEvents)) {
              return dummyEvents.includes(event.id) && event.status === `ADDED_TO_CART_OF_${props.ticket.ticket.id}`;
            }
            return false;
          })
          .map((event) => ({ ...event, status: 'CONFIRMED' }));
        console.log('sorted dummy events', sortedDummyEvents);
        props.dummyEventsSaved(props.ticket.ticket.id);
        if (sortedDummyEvents.length > 0) {
          props.updateDummyEventStatus({ status: 'CONFIRMED', event_ids: dummyEvents });
        }
      }
  
      const userCart = getEventIdsByStatus(`ADDED_TO_CART_OF_${props.ticket.ticket.id}`, props.cart.cartItems);
      console.log("user cart", userCart);
      const sortedCartItems = props.cart.cartItems
        .filter((item) => {
          if (Array.isArray(userCart)) {
            return userCart.includes(item.id) && item.status === `ADDED_TO_CART_OF_${props.ticket.ticket.id}`;
          }
          return false;
        })
        .map((item) => ({ ...item, status: 'CONFIRMED' }));
  
      props.cartconfirmed();
  
      if (sortedCartItems.length > 0) {
        props.createCart(props.ticket.ticket.id, { ticketId: props.ticket.ticket.id, cartItems: sortedCartItems });
        const sortedOrder = [];
  
        sortedCartItems.forEach((item) => {
          console.log('checking sorted order', item);
          sortedOrder.push({
            variantId: item.variantId,
            qty: parseInt(item.quantity, 10),
            variantName: item.variantName,
          });
        });
        console.log('sorted order implemented', sortedOrder);
        
        // Create the user order
        await props.createUserOrder({
          userId: props.ticket.ticket.user_id,
          ticketId: props.ticket.ticket.id,
          items: sortedOrder,
          storeId: props.ticket.ticket.storeId,
        });

        await props.setModalState({
            visible: true,
            modalName: 'loading',
            isLoading: false,
          });
        await props.updateTicketStatus(props.ticket.ticket.id, {
            ticketId: props.ticket.ticket.id,
            status: 'CONFIRMED'
          });
          
          // Redirect after some delay
          setTimeout(() => navigate("/dashboard/tickets"), 2000);
        } 
    } catch (error) {
      console.log("inside catch block with err", error)
      // Display an alert if an error occurs
      alert('An error occurred. Please try again later.');
      setTimeout(() => navigate("/dashboard/tickets"), 2000);

    }
  };
  console.log("props.cartItems", props.cartItems)
  return (
    <div className={classes.paper}>
      {props.modal.isLoading ? (
        <ModalWrapper />
      ) : (
        <div className={classes.paper}>
        <div
          style={{
            marginBottom: '20px', 
          }}
        >
          <h4 className={classes.header}>Are you sure you want to confirm this order?</h4>
        </div>
        {props.cartItems.filter(item => item.ticketId === props.cart.ticketId).map((cartItem) => (
          <div key={cartItem.id} className={classes.cartItem}>
            <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
              <img height="50px" width="50px" src={cartItem.variantImage} alt="" />
              <div>{cartItem.variantName} x <strong>{cartItem.quantity}</strong></div>
              <div> <strong>= {cartItem.price * cartItem.quantity}</strong> </div>
            </div>
          </div>
        ))}
        <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
          <Button variant="contained" color="primary" onClick={handleConfirmButtonClick}>
            Confirm
          </Button>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ cart, dummyEvent, event, ticket, order, modal }) => ({
  cart,
  dummyEvent,
  event,
  ticket,
  order,
  modal,
});

export default connect(mapStateToProps, {
  addDummyEvent,
  updateEventStatus,
  updateCartItemStatus,
  updateTicketStatus,
  confirmEvents,
  cartconfirmed,
  cartCleared,
  createDummyEvents,
  createUserOrder,
  // confirmUserOrder,
  dummyEventsSaved,
  eventsSaved,
  createCart,
  updateDummyEventStatus,
  setModalState,
})(AddConfirmTicketModal);
