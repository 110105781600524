
export const CLEAR_STORE = 'CLEAR_STORE';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const SET_MODAL_STATE = 'SET_MODAL_STATE';
export const SET_INTERNET_STATUS = 'SET_INTERNET_STATUS';
export const IS_LOADING = 'IS_LOADING';
export const LOADING_COMPLETED = 'LOADING_COMPLETED';




export const FETCH__USERS_LIST = 'FETCH__USERS_LIST';
export const FETCH__USERS_LIST_SUCCESS = 'FETCH__USERS_LIST_SUCCESS';
export const FETCH__USERS_LIST_FAILURE = 'FETCH__USERS_LIST_FAILURE';
export const FETCH__USER_DETAILS = 'FETCH__USER_DETAILS';
export const FETCH__USER_DETAILS_SUCCESS = 'FETCH__USER_DETAILS_SUCCESS';
export const FETCH__USER_DETAILS_FAILURE = 'FETCH__USER_DETAILS_FAILURE';
export const POST__USER_DETAILS = 'POST__USER_DETAILS';
export const POST__USER_DETAILS_SUCCESS = 'POST__USER_DETAILS_SUCCESS';
export const POST__USER_DETAILS_FAILURE = 'POST__USER_DETAILS_FAILURE';
export const PUT__USER_DETAILS = 'PUT__USER_DETAILS';
export const PUT__USER_DETAILS_SUCCESS = 'PUT__USER_DETAILS_SUCCESS';
export const PUT__USER_DETAILS_FAILURE = 'PUT__USER_DETAILS_FAILURE';
export const CLEAR__USER_DETAILS = 'CLEAR__USER_DETAILS';

export const RESET_EVENTS = 'RESET_EVENTS';
export const FETCH__TICKETS_LIST = 'FETCH__TICKETS_LIST';
export const FETCH__TICKETS_LIST_SUCCESS = 'FETCH__TICKETS_LIST_SUCCESS';
export const FETCH__TICKETS_LIST_FAILURE = 'FETCH__TICKETS_LIST_FAILURE';
export const FETCH__TICKET_DETAILS = 'FETCH__TICKET_DETAILS';
export const FETCH__TICKET_DETAILS_SUCCESS = 'FETCH__TICKET_DETAILS_SUCCESS';
export const FETCH__TICKET_DETAILS_FAILURE = 'FETCH__TICKET_DETAILS_FAILURE';
export const POST__TICKET_DETAILS = 'POST__TICKET_DETAILS';
export const POST__TICKET_DETAILS_SUCCESS = 'POST__TICKET_DETAILS_SUCCESS';
export const POST__TICKET_DETAILS_FAILURE = 'POST__TICKET_DETAILS_FAILURE';
export const PUT__TICKET_DETAILS = 'PUT__TICKET_DETAILS';
export const PUT__TICKET_DETAILS_SUCCESS = 'PUT__TICKET_DETAILS_SUCCESS';
export const PUT__TICKET_DETAILS_FAILURE = 'PUT__TICKET_DETAILS_FAILURE';
export const CLEAR__TICKET_DETAILS = 'CLEAR__TICKET_DETAILS';
export const SELECT_TICKET_DETAILS = 'SELECT_TICKET_DETAILS';
export const TICKET_CONFIRMED = 'TICKET_CONFIRMED';
export const PUT_TICKET_STATUS = 'PUT_TICKET_STATUS';

export const FETCH__EVENTS_LIST = 'FETCH__EVENTS_LIST';
export const FETCH__EVENTS_LIST_SUCCESS = 'FETCH__EVENTS_LIST_SUCCESS';
export const FETCH__EVENTS_LIST_FAILURE = 'FETCH__EVENTS_LIST_FAILURE';
export const FETCH__EVENT_DETAILS = 'FETCH__EVENT_DETAILS';
export const FETCH__EVENT_DETAILS_SUCCESS = 'FETCH__EVENT_DETAILS_SUCCESS';
export const FETCH__EVENT_DETAILS_FAILURE = 'FETCH__EVENT_DETAILS_FAILURE';
export const POST__EVENT_DETAILS = 'POST__EVENT_DETAILS';
export const POST__EVENT_DETAILS_SUCCESS = 'POST__EVENT_DETAILS_SUCCESS';
export const POST__EVENT_DETAILS_FAILURE = 'POST__EVENT_DETAILS_FAILURE';
export const PUT__EVENT_DETAILS = 'PUT__EVENT_DETAILS';
export const PUT__EVENT_DETAILS_SUCCESS = 'PUT__EVENT_DETAILS_SUCCESS';
export const PUT__EVENT_DETAILS_FAILURE = 'PUT__EVENT_DETAILS_FAILURE';
export const CLEAR__EVENT_DETAILS = 'CLEAR__EVENT_DETAILS';
export const IS_EVENTS_CHECKED = 'IS_EVENTS_CHECKED';
export const EVENTS_SAVED = 'EVENTS_SAVED';
export const  PUT_EVENT_STATUS = 'PUT_EVENT_STATUS';
export const EVENTS_CONFIRMED = 'EVENTS_CONFIRMED';

export const FETCH__DUMMY_EVENTS_LIST = 'FETCH__DUMMY_EVENTS_LIST';
export const FETCH__DUMMY_EVENTS_LIST_SUCCESS = 'FETCH__DUMMY_EVENTS_LIST_SUCCESS';
export const FETCH__DUMMY_EVENTS_LIST_FAILURE = 'FETCH__DUMMY_EVENTS_LIST_FAILURE';
export const FETCH__DUMMY_EVENT_DETAILS = 'FETCH__DUMMY_EVENT_DETAILS';
export const FETCH__DUMMY_EVENT_DETAILS_SUCCESS = 'FETCH__DUMMY_EVENT_DETAILS_SUCCESS';
export const FETCH__DUMMY_EVENT_DETAILS_FAILURE = 'FETCH__DUMMY_EVENT_DETAILS_FAILURE';
export const POST__DUMMY_EVENT_DETAILS = 'POST__DUMMY_EVENT_DETAILS';
export const POST__DUMMY_EVENT_DETAILS_SUCCESS = 'POST__DUMMY_EVENT_DETAILS_SUCCESS';
export const POST__DUMMY_EVENT_DETAILS_FAILURE = 'POST__DUMMY_EVENT_DETAILS_FAILURE';
export const PUT__DUMMY_EVENT_DETAILS = 'PUT__DUMMY_EVENT_DETAILS';
export const PUT__DUMMY_EVENT_DETAILS_SUCCESS = 'PUT__DUMMY_EVENT_DETAILS_SUCCESS';
export const PUT__DUMMY_EVENT_DETAILS_FAILURE = 'PUT__DUMMY_EVENT_DETAILS_FAILURE';
export const CLEAR__DUMMY_EVENT_DETAILS = 'CLEAR__DUMMY_EVENT_DETAILS';
export const ADD__DUMMY_EVENT = 'ADD__DUMMY_EVENT';
export const REMOVE__DUMMY_EVENT = 'REMOVE__DUMMY_EVENT';
export const CLEAR__DUMMY_EVENTS = 'CLEAR__DUMMY_EVENTS';
export const SAVE__DUMMY_EVENTS = 'SAVE__DUMMY_EVENTS';
export const RESET_DUMMY_EVENTS = 'RESET_DUMMY_EVENTS';
export const IS_DUMMY_EVENT_CHECKED = 'IS_DUMMY_EVENT_CHECKED';
export const DUMMY_EVENTS_CONFIRMED = 'DUMMY_EVENTS_CONFIRMED';
export const POST__DUMMY_EVENTS = 'POST__DUMMY_EVENTS';

export const FETCH__VIDEOS_LIST = 'FETCH__VIDEOS_LIST';
export const FETCH__VIDEOS_LIST_SUCCESS = 'FETCH__VIDEOS_LIST_SUCCESS';
export const FETCH__VIDEOS_LIST_FAILURE = 'FETCH__VIDEOS_LIST_FAILURE';
export const FETCH__VIDEO_DETAILS = 'FETCH__VIDEO_DETAILS';
export const FETCH__VIDEO_DETAILS_SUCCESS = 'FETCH__VIDEO_DETAILS_SUCCESS';
export const FETCH__VIDEO_DETAILS_FAILURE = 'FETCH__VIDEO_DETAILS_FAILURE';
export const POST__VIDEO_DETAILS = 'POST__VIDEO_DETAILS';
export const POST__VIDEO_DETAILS_SUCCESS = 'POST__VIDEO_DETAILS_SUCCESS';
export const POST__VIDEO_DETAILS_FAILURE = 'POST__VIDEO_DETAILS_FAILURE';
export const PUT__VIDEO_DETAILS = 'PUT__VIDEO_DETAILS';
export const PUT__VIDEO_DETAILS_SUCCESS = 'PUT__VIDEO_DETAILS_SUCCESS';
export const PUT__VIDEO_DETAILS_FAILURE = 'PUT__VIDEO_DETAILS_FAILURE';
export const CLEAR__VIDEO_DETAILS = 'CLEAR__VIDEO_DETAILS';


export const FETCH__CARTS_LIST = 'FETCH__CARTS_LIST';
export const FETCH__CARTS_LIST_SUCCESS = 'FETCH__CARTS_LIST_SUCCESS';
export const FETCH__CARTS_LIST_FAILURE = 'FETCH__CARTS_LIST_FAILURE';
export const FETCH__CART_DETAILS = 'FETCH__CART_DETAILS';
export const FETCH__CART_DETAILS_SUCCESS = 'FETCH__CART_DETAILS_SUCCESS';
export const FETCH__CART_DETAILS_FAILURE = 'FETCH__CART_DETAILS_FAILURE';
export const POST__CART_DETAILS = 'POST__CART_DETAILS';
export const POST__CART_DETAILS_SUCCESS = 'POST__CART_DETAILS_SUCCESS';
export const POST__CART_DETAILS_FAILURE = 'POST__CART_DETAILS_FAILURE';
export const PUT__CART_DETAILS = 'PUT__CART_DETAILS';
export const PUT__CART_DETAILS_SUCCESS = 'PUT__CART_DETAILS_SUCCESS';
export const PUT__CART_DETAILS_FAILURE = 'PUT__CART_DETAILS_FAILURE';
export const CLEAR__CART_DETAILS = 'CLEAR__CART_DETAILS';
export const UPDATE_CART_TICKET = 'UPDATE_CART_TICKET';
export const DELETE__CART_DETAILS = 'DELETE__CART_DETAILS';
export const ADD__TO_CART = 'ADD__TO_CART';
export const REMOVE__FROM_CART = 'REMOVE__FROM_CART';
export const CLEAR__CART = 'CLEAR__CART';
export const CART_SAVED = 'CART_SAVED';
export const CART_CONFIRMED = 'CART_CONFIRMED';

export const FETCH__PRODUCTS_LIST = 'FETCH__PRODUCTS_LIST';
export const FETCH__PRODUCTS_LIST_SUCCESS = 'FETCH__PRODUCTS_LIST_SUCCESS';
export const FETCH__PRODUCTS_LIST_FAILURE = 'FETCH__PRODUCTS_LIST_FAILURE';
export const ADD__PRODUCT = 'ADD__PRODUCT';
export const RESET__PRODUCT = 'RESET__PRODUCT';
export const CREATE_USER_ORDER = 'CREATE_USER_ORDER';
export const CREATE_USER_ORDER_SUCCESS = 'CREATE_USER_ORDER_SUCCESS';
export const CREATE_USER_ORDER_FAILURE = 'CREATE_USER_ORDER_FAILURE';

export const CONFIRM_USER_ORDER= 'CONFIRM_USER_ORDER';
export const CONFIRM_USER_ORDER_SUCCESS= 'CONFIRM_USER_ORDER_SUCCESS';
export const CONFIRM_USER_ORDER_FAILURE = 'CONFIRM_USER_ORDER_FAILURE';
export const EVENTS_ADDED_TO_CART = 'EVENTS_ADDED_TO_CART';
export const DUMMY_EVENTS_ADDED_TO_CART = 'DUMMY_EVENTS_ADDED_TO_CART';
export const PUT_DUMMY_EVENT_STATUS ='PUT_DUMMY_EVENT_STATUS';
export const CART_STATUS_CONFIRMED ='CART_STATUS_CONFIRMED';
export const REMOVE_DUMMY_EVENTS_FROM_CART ='REMOVE_DUMMY_EVENTS_FROM_CART';
export const REMOVE_EVENTS_FROM_CART ='REMOVE_EVENTS_FROM_CART';
export const FETCH__LOGS_LIST = 'FETCH__LOGS_LIST';
export const FETCH__LOGS_LIST_SUCCESS = 'FETCH__LOGS_LIST_SUCCESS';
export const FETCH__LOGS_LIST_FAILURE = 'FETCH__LOGS_LIST_FAILURE';
export const FETCH__MACHINE_DETAILS = 'FETCH__MACHINE_DETAILS';
export const FETCH__MACHINE_DETAILS_SUCCESS = 'FETCH__MACHINE_DETAILS_SUCCESS';
export const FETCH__MACHINE_DETAILS_FAILURE = 'FETCH__MACHINE_DETAILS_FAILURE';
export const FETCH__MACHINES_LIST = 'FETCH__MACHINES_LIST';
export const FETCH__MACHINES_LIST_SUCCESS = 'FETCH__MACHINES_LIST_SUCCESS';
export const FETCH__MACHINES_LIST_FAILURE = 'FETCH__MACHINES_LIST_FAILURE';


// ALL OTHER CONSTANTS 

// export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
// export const SET_STORE_SEARCH_QUERY = 'SET_SEARCH_QUERY';
// export const SET_SEARCH_HISTORY = 'SET_SEARCH_HISTORY';
// export const SET_STORE_SEARCH_HISTORY = 'SET_SEARCH_HISTORY';
// export const FETCH_STORES = 'FETCH_STORES';
// export const FETCH_SEARCHED_PRODUCTS = 'FETCH_SEARCHED_PRODUCTS';
// export const FETCH_SEARCHED_STORE_PRODUCTS = 'FETCH_SEARCHED_STORE_PRODUCTS';
// export const FETCH_CART = 'FETCH_CART';
// export const FETCH_OFFLINE_CART = 'FETCH_OFFLINE_CART';
// export const FETCH_BILLING_SUMMARY = 'FETCH_BILLING_SUMMARY';
// export const FETCH_APP_UPDATE_DETAILS = 'FETCH_APP_UPDATE_DETAILS';
// export const SET_STORES = 'SET_STORES';
// export const SET_SEARCHED_PRODUCTS = 'SET_SEARCHED_PRODUCTS';
// export const SET_SEARCHED_STORE_PRODUCTS = 'SET_SEARCHED_STORE_PRODUCTS';
// export const SET_CART = 'SET_CART';
// export const SET_ONLINE_CART = 'SET_ONLINE_CART';
// export const SET_LOADER = 'SET_LOADER';
// export const SET_APP_UPDATE_CHECK = 'SET_APP_UPDATE_CHECK';
// export const SET_CART_ITEM = 'SET_ORDER_ITEM';
// export const CLEAR_CART = 'CLEAR_ORDER';
// export const DELETE_CART_ITEM = 'DELETE_ORDER_ITEM';

// ----ref const below- will be deleated later
